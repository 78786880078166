import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../../context/MenuContext";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  useClonarRankingMutation,
  useGetMostrarRankingModalidadQuery,
  useGuardarHistorialRankingMutation,
} from "../../../../redux/services/ranking";
import TablaRankingAdmin from "./componentes/TablaRankingAdmin";
import { toast } from "react-toastify";
import { useGetListaModalidadesSelectQuery } from "../../../../redux/services/modalidad";

const RankingAdmin = () => {
  const { menuState } = useContext(MenuContext);
  const [ranking, setRanking] = useState([]);
  const [idModalidad, setIdModalidad] = useState(14);
  const [nombreModalidad, setNombreModalidad] = useState("Parapente XC");
  const {
    data: dataRanking,
    isLoading: isLoadingRanking,
    refetch,
  } = useGetMostrarRankingModalidadQuery(idModalidad);
  const [dataModalidades, setDataModalidades] = React.useState();
  const { data: resModalidades, isLoading: isLoadingModalidad } =
    useGetListaModalidadesSelectQuery();
  const [hasError, setHasError] = React.useState({
    modalidad: false,
  });

  React.useEffect(() => {
    try {
      setDataModalidades(resModalidades?.listarModalidades);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }

    return () => {
      setDataModalidades();
    };
  }, [resModalidades]);

  useEffect(() => {
    refetch();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataRanking?.listaRanking;
        setRanking(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataRanking, idModalidad]);

  const [clonarRanking, { error: errorClonarRanking }] =
    useClonarRankingMutation();

  const [guardarHistorialRanking, { error: errorGuardarHistorialRanking }] = useGuardarHistorialRankingMutation();

  const handleChange = (e) => {
    const { value } = e.target;

    const datosFiltrados = dataModalidades?.filter((dato) => {
      return dato.id === value;
    });
    setNombreModalidad(datosFiltrados[0].nombre);
    setIdModalidad(value);
  };

  const handleCalcularRanking = async () => {
    try {
      const modId = await clonarRanking();
      const historial = await guardarHistorialRanking(idModalidad);
      if (modId.error) throw new Error(errorClonarRanking);
      if (historial.error) throw new Error(errorGuardarHistorialRanking);
      toast.success("Ranking publicado!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
    } catch (error) {
      toast.error("Error calculando Ranking!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const isMobile = window.innerWidth <= 768;
  const style = isMobile ? {} : { justifyContent: 'center' };

  return (
    <>
      <Box
        className={`${menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
      >
        <Grid container style={{ flexDirection: "row" }}>
          <Grid
            item
            xs={12}
            style={{ flexDirection: "column" }}
            className="gridColumnaPXC"
          >
            <Box
              container
              style={{
                marginTop: "2%",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "50%", margin: "10px" }}>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleCalcularRanking}
                  disabled={ranking === null}
                >
                  Publicar Ranking
                </Button>
              </div>
              <div style={{ width: "50%" }}>
                {resModalidades ? (
                  <FormControl
                    fullWidth
                    sx={{ textAlign: "center" }}
                    error={hasError.modalidad}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Modalidad
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idModalidad"
                      value={idModalidad}
                      label="Modalidad"
                      onChange={handleChange}
                      required
                    >
                      {dataModalidades?.map((modalidad) => (
                        <MenuItem
                          disabled={modalidad.id !== 14}
                          key={modalidad.id} value={modalidad.id}>
                          {modalidad.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    {hasError.modalidad && (
                      <FormHelperText
                        sx={{ color: "#D32F2F", textAlign: "center" }}
                      >
                        Campo requerido!
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : isLoadingModalidad ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        width: "50%",
                        height: "50%",
                        textAlign: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ width: "30px", height: "30px" }}
                      />
                      <Typography
                        style={{
                          color: "rgb(0, 150, 215)",
                          fontSize: "15px",
                          marginTop: "10px",
                        }}
                      >
                        Cargando Modalidad
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography className="Titulo">
                    {" No hay Modalidad para mostrar."}
                  </Typography>
                )}
              </div>
            </Box>
            {ranking ? (
              <Box width={"100%"}>
                {ranking?.length === 0 ? (
                  <Typography className="Titulo" style={{ margin: "20px" }}>
                    No hay Ranking para mostrar.
                  </Typography>
                ) : (
                  <Grid
                    container
                    className="GridItemCN"
                    sx={{ margin: "10px", overflowX: "auto" }}
                    style={style}
                  >
                    <TablaRankingAdmin
                      ranking={ranking}
                      nombreModalidad={nombreModalidad}
                    />
                  </Grid>
                )}
              </Box>
            ) : isLoadingRanking ? (
              <CircularProgress />
            ) : (
              <Box>
                <Typography
                  className="Titulo"
                  style={{
                    margin: "20px",
                    fontWeight: "bold",
                    marginTop: "50px",
                  }}
                >
                  No hay Ranking para mostrar.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RankingAdmin;
