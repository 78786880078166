import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuLateral from "../../../../components/MenuLateral";
import { MenuContext } from "../../../../context/MenuContext";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import {
  useGetMostrarRankingPublicadoModalidadQuery,
  useGetUltimaFechaRankingQuery,
} from "../../../../redux/services/ranking";
import { useSelector } from "react-redux";

const Ranking = () => {
  const { menuState } = useContext(MenuContext);
  const [ranking, setRanking] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const state = useSelector((state) => state.modalidad);

  const { data, isLoading: isLoadingRanking } =
    useGetMostrarRankingPublicadoModalidadQuery(state?.modalidad?.id);

  const { data: dataFecha } =
    useGetUltimaFechaRankingQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = data?.listaRanking;
        setRanking(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [state, data]);

  const totalPages = Math.ceil(ranking?.length / pageSize);

  const paginatedRanking = ranking?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const opcMenu = [
    {
      opcion: "Novedades",
      link: "/modalidad/*",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/Reglamento",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ResultadosPublicados",
      state,
    },
    {
      opcion: "Ranking",
      link: "/Ranking",
      state,
    },
    {
      opcion: "Historial Ranking",
      link: "/HistorialRanking",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        <MenuLateral opcMenu={opcMenu} menuTitulo={"Ranking"} typeMenu={false} />
        <Box
          className={`${menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
            }`}
        >
          {isLoadingRanking && (
            <div
              style={{
                position: "relative",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "20px",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                className="Titulo"
                variant="h5"
                style={{
                  width: "100%",
                  textAlign: "center",
                  zIndex: 1,
                  paddingRight: "5px",
                }}
              >
                Cargando Ranking...{" "}
              </Typography>
              <CircularProgress
                style={{ position: "relative", height: "15px", width: "15px" }}
              />
            </div>
          )}
          {!isLoadingRanking &&
            paginatedRanking &&
            paginatedRanking.length > 0 && (
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell
                          className="Titulo"
                          colSpan={5}
                          align="center"
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              my: 2,
                              fontWeight: "bold",
                              border: "2px dotted grey",
                            }}
                          >
                            Ranking de {state.modalidad.nombre}, al
                            01-{dataFecha}
                          </Typography>
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        <>
                          <TableRow>
                            <TableCell align="center" style={{ width: "5%" }}>
                              <Typography variant="subtitle1">
                                <span
                                  className="Titulo"
                                  style={{ display: "block" }}
                                >
                                  Posición:{" "}
                                </span>
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: "20%" }}>
                              <Typography variant="subtitle1">
                                <span
                                  className="Titulo"
                                  style={{ display: "block" }}
                                >
                                  Licencia:{" "}
                                </span>
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: "40%" }}>
                              <Typography variant="body1" className="Titulo">
                                Nombre:
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: "20%" }}>
                              <Typography variant="body1" className="Titulo">
                                Puntuación:
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: "20%" }}>
                              <Typography variant="body1" className="Titulo">
                                <i class="fa fa-info" aria-hidden="true"></i>
                                Info:{" "}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {paginatedRanking.map((posicion, indice) => (
                            <TableRow key={indice}>
                              <TableCell align="center" style={{ width: "5%" }}>
                                <Typography variant="subtitle1">
                                  # {indice + (currentPage - 1) * 10 + 1}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: "20%" }}
                              >
                                <Typography variant="subtitle1">
                                  {posicion.id}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: "40%" }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {posicion.nombre}{" "}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: "20%" }}
                              >
                                <Typography
                                  target="_blank"
                                  sx={{
                                    mx: 1,
                                    textAlign: "center",
                                    color: "black",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {posicion.puntuacion}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: "20%" }}
                              >
                                <Typography
                                  component={Link}
                                  to={"/MangasDetalle"}
                                  state={{ ...state, posicion }}
                                  variant="body1"
                                  className="Titulo"
                                >
                                  <InfoIcon color="primary" />
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Divider sx={{ width: "100%", m: 2 }} />
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="outlined"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </Button>
                  <Typography
                    variant="body1"
                    style={{ margin: "5px 10px 0px 10px" }}
                  >
                    Pag.{currentPage} / {totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">>"}
                  </Button>
                </Box>
              </Grid>
            )}
          {!isLoadingRanking && (!ranking || ranking.length === 0) && (
            <Typography className="Titulo">
              No hay Ranking para mostrar.
            </Typography>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Ranking;
