import React, { useState } from 'react';
import './Styles/NovedadCard.css';
import { Button, Card, CardActionArea, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import noimage from '../static/img/descarga.png';
import Novedad from '../routes/Novedad';
import NovedadInicioAdmin from '../routes/cms/pages/inicio/NovedadInicioAdmin';

function NovedadCard({ novedad }) {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://localhost:5000';

  const [modalState, setModalState] = useState(false);

  const handleOpenModal = () => {
    setModalState(true);
  }

  const handleCloseModal = () => {
    setModalState(false);
  }

  return (
    <React.Fragment>
      <Card className="CartaNovedad" sx={{ height: '100%', width: '100%', }}>
        <CardMedia
          className="imagenCN"
          component={"img"}
          sx={{ backgroundColor: novedad?.imagen ? '#COCOCO' : '#FFF', objectFit: novedad?.imagen ? 'cover' : 'contain', }}
          src={novedad?.imagen && novedad?.imagen !== 'null' ? `${API_BASE_URL}/images/${novedad.imagen}` : noimage}
          image={novedad.source}
          title={novedad.titulo}
        />

        <CardContent className='CardContentCN'>
          <Typography className="Titulo">{novedad.titulo}</Typography>
        </CardContent>
        <Divider sx={{ width: '90%', margin: '5px' }} />
        <CardActionArea className='CardActionAreaCN'>
          <Button className='BotonBody' onClick={handleOpenModal}>Ver</Button>
        </CardActionArea>
      </Card>
      <NovedadInicioAdmin
        ModalState={modalState}
        CloseModal={handleCloseModal}
        Novedad={novedad}
        Origen={'vermas'}
      />
    </React.Fragment>
  );
}

export default NovedadCard