import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuLateral from "../../../../components/MenuLateral";
import { MenuContext } from "../../../../context/MenuContext";
import { useGetListaReglamentosQuery } from "../../../../redux/services/reglamento";
import ListaReglamentosPublico from "./componentes/ListaReglamentosPublico";

const ReglamentosPublico = () => {
  const { state } = useLocation();
  const isMobile = window.innerWidth <= 768;
  const { menuState } = useContext(MenuContext);
  const [reglamentos, setReglamentos] = useState([]);
  const initialState = {
    nombre: "",
    idModalidad: state.modalidad.id,
    reglamento: "",
  };
  const [newData, setNewData] = useState(initialState);
  const [nombreModalidad, setNombreModalidad] = useState(
    state?.modalidad?.nombre
  );

  const { data: dataReglamentos, isLoading: isLoadingReglamentos } =
    useGetListaReglamentosQuery(newData?.idModalidad);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setReglamentos(dataReglamentos?.listaReglamento);
      } catch (error) {
        console.error("Error al obtener los reglamentos:", error);
      }
    };
    fetchData();
    return () => {
      setReglamentos([]);
    };
  }, [newData.idModalidad, dataReglamentos]);

  const opcMenu = [
    {
      opcion: "Acerca de...",
      link: "/modalidad/Cnd",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ModalidadCnd",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/ReglamentoCnd",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Records",
      link: "/RecordPublico",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const opcMenuTemp = [
    {
      opcion: "Novedades",
      link: "/modalidad/*",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/Reglamento",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ResultadosPublicados",
      state,
    },
    {
      opcion: "Ranking",
      link: "/Ranking",
      state,
    },
    {
      opcion: "Historial Ranking",
      link: "/HistorialRanking",
      state,
    },
    {
      opcion: "Seleccion Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const opcMenu2 = opcMenuTemp.filter(item => {
    if ((item.opcion === "Historial Ranking" || item.opcion === "Ranking") && state?.modalidad?.id !== 14) {
      return false;
    }
    return true;
  });
  return (
    <>
      <MenuLateral
        opcMenu={
          state?.modalidad.nombre.toLowerCase() === "cnd" ? opcMenu : opcMenu2
        }
        menuTitulo={`Reglamentos`}
        typeMenu={false}
      />
      <Box
        className={`${menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        style={{ marginTop: isMobile ? "15%" : "6%" }}
      >
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Typography
            className="Titulo"
            variant="h5"
            sx={{
              textAlign: "center",
              my: 2,
              fontWeight: "bold",
              border: "2px dotted grey",
            }}
          >
            Reglamentos de {nombreModalidad}{" "}
          </Typography>
          <Grid item xs={12} sx={{ my: 5 }}>
            {isLoadingReglamentos ? (
              <Box style={{ margin: "20px", textAlign: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                {!reglamentos || reglamentos?.length === 0 ? (
                  <Typography
                    className="Titulo"
                    style={{ margin: "20px", textAlign: "center" }}
                  >
                    No hay Reglamentos para mostrar.
                  </Typography>
                ) : (
                  <Box>
                    <Typography
                      className="tituloContenido"
                      style={{ margin: "20px", textAlign: "center" }}
                    >
                      El reglamento vigente es el primero de este listado. En caso que no haya modificaciones en el tiempo, el reglamento vigente puede tener el título de un año o más, anteriores
                    </Typography>
                    <ListaReglamentosPublico
                      reglamentos={reglamentos}
                      nombreModalidad={nombreModalidad}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReglamentosPublico;
