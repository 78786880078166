import React, { useCallback, useRef, useState } from 'react';
import './Styles/ModalCompetencia.css';
import { Box, Button, CircularProgress, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography, } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useConfirmationModal from '../hooks/useConfirmationModal ';
import { useBorrarCompetenciaByIdMutation, useGetImagenesByCompetenciaQuery, useModificarCompetenciaMutation } from '../redux/services/competencia';
import { useGetListaModalidadesSelectQuery, useGetModalidadByIdQuery } from '../redux/services/modalidad';
import { useGetListaTipoCompetenciaQuery } from '../redux/services/tipoCompetencia';
import { LoadingButton } from '@mui/lab';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://localhost:5000';

function ModalCompetencia({ ModalState, CloseModal, Competencia, CurrentId, Origen = 'readonly' }) {

  const [isEditable, setIsEditable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [newData, setNewData] = useState(Competencia);
  const isMobile = window.innerWidth <= 768;
  const [errorInicio, setErrorInicio] = React.useState(null);
  const [errorFin, setErrorFin] = React.useState(null);

  const [dataModalidades, setDataModalidades] = useState();
  const { data: resModalidades, isLoading: isLoadingModalidades } = useGetListaModalidadesSelectQuery();

  const desarrolloModalCompetenciaEditRef = useRef(null);
  React.useEffect(() => {
    try {
      setDataModalidades(resModalidades?.listarModalidades)
    } catch (error) {
      console.log(error)
    }

    return () => {
      setDataModalidades();
    }
  }, [resModalidades]);


  const { data: dataModalidad, isLoading: isLoadingModalidadIndividual, isError: isErrorModalidadIndividual } = useGetModalidadByIdQuery(Competencia?.idModalidadFk);
  const [modalidad, setModalidad] = useState('');

  React.useEffect(() => {
    const fetchModalidad = async () => {
      try {
        const result = await dataModalidad;

        if (result && result.modalidadIndividual) {
          setModalidad(result.modalidadIndividual.nombre);
        }
      } catch (error) {
        console.error('Error al obtener la modalidad:', error);
      }
    };

    fetchModalidad();
  }, [dataModalidad]);

  const [dataTipoCompetencias, setDataTipoCompetencias] = useState();
  const { data: resTipoCompetencia } = useGetListaTipoCompetenciaQuery();

  React.useEffect(() => {

    try {
      setDataTipoCompetencias(resTipoCompetencia?.listaTipoCompetencia)
    } catch (error) {
      console.log('Error al obtener los Tipo de Competencia:', error)
    }

    return () => {
      setDataTipoCompetencias();
    }
  }, [resTipoCompetencia]);

  React.useEffect(() => {
    if (isErrorModalidadIndividual) {
      setModalidad('Cargando modalidad...');
    }
  }, [isErrorModalidadIndividual]);

  React.useEffect(() => {
    if (isLoadingModalidadIndividual) {
      setModalidad('Cargando modalidad...');
    }
  }, [isLoadingModalidadIndividual]);

  const { data: dataImagenes } = useGetImagenesByCompetenciaQuery(Competencia.id)

  const [validationErrors, setValidationErrors] = useState({
    titulo: false,
    ubicacion: false,
    desarrollo: false,
  });

  const { ModalConfirmacion } = useConfirmationModal();

  const handleRequired = () => {
    const errors = {
      titulo: !newData?.titulo || newData.titulo.trim() === "",
      ubicacion: !newData?.ubicacion || newData.ubicacion.trim() === "",
      desarrollo: !newData?.desarrollo || newData.desarrollo.trim() === "",
    };
    setValidationErrors(errors);

    if (Object.values(errors).some(error => error)) {
      return true;
    }
    return false;
  };

  const [modificarCompetencia] = useModificarCompetenciaMutation();
  const [isUpdating, setIsUpdating] = useState(false)
  const handleEdit = async () => {

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    if (dayjs(dayjs(newData.fechaFin).format('DD/MM/YYYY'), 'date').isBefore(dayjs(dayjs(newData.fechaInicio).format('DD/MM/YYYY')), 'date')) {
      toast.error("Fecha inicio debe ser menor que  final"); return;
    }

    if (validationErrors.titulo) { toast.error("Título es campo obligatorio!"); return; }
    if (validationErrors.ubicacion) { toast.error("ubicacion es campo obligatorio!"); return; }
    if (validationErrors.desarrollo) { toast.error("desarrollo es campo obligatorio!"); return; }
    let updatedData = { ...newData };

    if (typeof updatedData.imagen === 'string') {
      updatedData.imagen = null;
    }

    try {
      setIsUpdating(true);
      const resp = await modificarCompetencia({
        id: CurrentId,
        nuevaCompetencia: updatedData,
      });
      if (resp.error) throw new Error(resp.error)
      toast.success('¡Competencia actualizada con éxito!', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'success1',
      });

    } catch (error) {
      toast.error('Error actualizando competencia!', {
        position: toast.POSITION.TOP_CENTER
      });
      console.log(error)
    }
    setIsEditable(false);
    setIsUpdating(false)
  }
  const [borrarCompetenciaById, { isFetching, isError, error }] = useBorrarCompetenciaByIdMutation();


  const handleDelete = async () => {
    try {
      await borrarCompetenciaById(CurrentId);
      if (!isFetching) { if (isError) console.log('Error borrando competencia', error) }
      setIsDelete(false);
      toast.success('¡Competencia eliminada con Éxito!', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'success2',
      });
    } catch (error) {
      toast.error('Error!', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  const handleCancelar = () => {
    setIsEditable(false);
    setIsDelete(false);
    !isEditable && setNewData({});
  }


  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name !== 'idModalidadFk') {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === '',
      }));
    }

  };

  const [hasError, setHasError] = useState({
    modalidad: false,
    tipoCompetencia: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value,
    });


    if (name === 'idModalidadFk') {
      setHasError({ ...hasError, modadlidad: false }); return;
    }
    if (name === 'idTipoCompetenciaFk') {
      setHasError({ ...hasError, tipoCompetencia: false }); return;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === '',
    }));

  };

  const handleCloseModal = () => {
    CloseModal();
    setIsEditable(false);
    setIsDelete(false);
  }

  const errorMessageInicio = React.useMemo(() => {
    switch (errorInicio) {
      case 'disablePast': {
        return 'Por favor seleccione una fecha vigente';
      }

      case 'invalidDate': {
        return 'Fecha inválida';
      }

      default: {
        return '';
      }
    }
  }, [errorInicio]);

  const errorMessageFin = React.useMemo(() => {
    switch (errorFin) {
      case 'disablePast': {
        return 'Por favor seleccione una fecha vigente';
      }


      default: {
        return '';
      }
    }
  }, [errorFin]);


  const BoxConFondo = {
    backgroundImage: `url(${API_BASE_URL}/images/${Competencia?.imagen})`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    textAlign: "center",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  };

  const [fileName, setFileName] = useState('');
  const [newImagenVistaPreviaCompetencia, setNewImagenVistaPreviaCompetencia] = useState('')
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name)

    if (file) {
      setNewData({
        ...newData,
        imagen: file,
      });
      const readerModalCompetencia = new FileReader();
      readerModalCompetencia.onload = (event) => {
        setNewImagenVistaPreviaCompetencia(event.target.result)
      };
      readerModalCompetencia.readAsDataURL(file);
    }
  };

  function FormattedInput({ value }) {
    return (
      <Typography
        component="div"
        variant="body1"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  }

  const handleChangeDesarrollo = useCallback((e) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value,
    });

    if (isNaN(value)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === '',
      }));
    }
  }, [setNewData]);

  React.useEffect(() => {
    const initEditor = () => {

      if (desarrolloModalCompetenciaEditRef.current) {
        window.tinymce?.init({
          selector: "#desarrolloModalCompetenciaEdit",
          menubar: false,
          min_height: 250,
          max_height: 250,
          width: "100%",
          plugins: ["paste", "link",],
          toolbar: "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | link",
          branding: false,
          setup: (editor) => {
            editor.on("change", () => {
              const content = editor.getContent();
              handleChangeDesarrollo({ target: { name: "desarrollo", value: content } });
            });
          },
        });
      }
    }

    initEditor();

    return () => {
      if (window.tinymce?.get(desarrolloModalCompetenciaEditRef.current?.id)) {
        window.tinymce.get(desarrolloModalCompetenciaEditRef.current?.id).remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);

  return (
    <React.Fragment >

      <Modal key={Competencia.idPublicacion} open={ModalState} onClose={handleCloseModal} className="ModalC" >

        {
          isEditable
            ?
            <Box className="ContenedorMC" style={{ width: '80%', marginTop: '50px' }}>
              <Box className="ContSecundarioMC" sx={{ gap: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Título"
                      aria-label="Título"
                      className="Titulo"
                      value={newData.titulo}
                      name='titulo'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={validationErrors.titulo}
                      helperText={validationErrors.titulo && 'Este campo es requerido'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(Competencia.fechaInicio)}
                        label='Fecha Inicio'
                        name='fechaInicio'
                        disableTextEditor
                        inputFormat="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        onChange={(newValue) => {
                          const fecha = dayjs(newValue).format('YYYY-MM-DD');
                          try {
                            setNewData((prevNewData) => ({
                              ...prevNewData,
                              fechaInicio: fecha,
                            }));
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        onError={(newError) => setErrorInicio(newError)}
                        slotProps={{
                          textField: {
                            helperText: errorMessageInicio,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Link"
                      aria-label="asunto"
                      value={newData.asunto && (newData.asunto.toLowerCase() === 'null' || newData.asunto.toLowerCase() === 'undefined') ? '' : newData.asunto}
                      name='asunto'
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(Competencia.fechaFin)}
                        label='Fecha Finalización'
                        name='fechaFin'
                        minDate={dayjs(Competencia.fechaInicio)}
                        disableTextEditor
                        inputFormat="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        onChange={(newValue) => {
                          const fecha = dayjs(newValue).format('YYYY-MM-DD');
                          try {
                            setNewData((prevNewData) => ({
                              ...prevNewData,
                              fechaFin: fecha,
                            }));
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        onError={(errorFin) => { setErrorFin(errorFin) }}
                        slotProps={{
                          textField: {
                            helperText: errorMessageFin,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Divider className="DividerCN" style={{ width: '100%' }} />

                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Modalidad</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="idModalidadFk"
                          value={newData.idModalidadFk}
                          label="Modalidad"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        >
                          {isLoadingModalidades ? (
                            <CircularProgress />
                          ) : (
                            dataModalidades?.map((modalidad) => (
                              <MenuItem key={modalidad.id} value={modalidad.id}>{modalidad.nombre}</MenuItem>
                            ))
                          )}
                        </Select>
                        {hasError.modalidad && <FormHelperText sx={{ color: '#D32F2F' }}>Campo requerido!</FormHelperText>}
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Tipo de Competencia</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="idTipoCompetenciaFk"
                          value={newData.idTipoCompetenciaFk}
                          label="Tipo de Competencia"
                          onChange={handleChange}
                          required
                        >
                          {dataTipoCompetencias?.map((tipo) => (
                            <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>
                          ))}
                        </Select>
                        {hasError.tipoCompetencia && <FormHelperText sx={{ color: '#D32F2F' }}>Campo requerido!</FormHelperText>}
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      label='Ubicación'
                      aria-label="Ubicación"
                      className="Subtitulo"
                      value={newData.ubicacion}
                      name='ubicacion'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={validationErrors.ubicacion}
                      helperText={validationErrors.ubicacion && 'Este campo es requerido'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      inputRef={desarrolloModalCompetenciaEditRef}
                      required
                      multiline
                      rows={9}
                      label='Desarrollo'
                      aria-label="desarrollo"
                      className="Subtitulo"
                      value={newData.desarrollo}
                      name='desarrollo'
                      onChange={handleChangeDesarrollo}
                      error={validationErrors.desarrollo}
                      helperText={validationErrors.desarrollo && 'Este campo es requerido'}
                      id="desarrolloModalCompetenciaEdit"
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box className="BoxImagenCN" style={{ maxWidth: '90%' }}>
                      <Typography className="Texto">Imagen Encabezado</Typography>
                      <input
                        label="Imagen"
                        name="imagen"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }} // Ocultamos el input de archivo para personalizarlo
                        id="imagenInputEdit"
                      />
                      {newImagenVistaPreviaCompetencia ? (
                        <img
                          src={newImagenVistaPreviaCompetencia}
                          alt={fileName !== '' ? fileName : 'Vista previa de la imagen'}
                          style={{ maxWidth: "20%", marginTop: "16px" }}
                        />
                      ) : newData.imagen && (newData.imagen !== null || newData.imagen !== 'null') ? (
                        <Box style={{
                          width: "100%",
                          height: "40%",
                          display: 'flex',
                          alignContent: 'center',
                          justifyContent: 'center'
                        }}>
                          <Box style={{
                            backgroundImage: `url(${API_BASE_URL}/images/${newData.imagen})`,
                            width: "30%",
                            height: "80%",
                            marginTop: "16px",
                            backgroundSize: "fill",
                            backgroundPosition: "center center",
                          }} />
                        </Box>
                      ) : (
                        <div style={{ marginTop: "10%", backgroundColor: 'transparent' }} />
                      )}
                      <Divider className="DividerCN" />
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <label htmlFor="imagenInputEdit">
                          <Button
                            variant="outlined"
                            component="span"
                            color="primary"
                            className="BotonBody BotonCN"
                            sx={{}}
                          >
                            Seleccionar
                          </Button>
                        </label>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid container justifyContent="flex-end" style={{ marginTop: '15px' }}>
                    {!isEditable ? (
                      isDelete ? (
                        <>
                          <Button onClick={handleDelete} className='BotonBody'>Eliminar</Button>
                          <Button onClick={handleCancelar} className='BotonBody'>Cancelar</Button>
                        </>
                      ) : null
                    ) : (
                      <>
                        <LoadingButton loading={isUpdating} onClick={handleEdit} className='BotonBody'>Guardar</LoadingButton>
                        <Button onClick={handleCancelar} className='BotonBody'>Cancelar</Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            :
            <>

              <Box className="ContenedorN" style={{ width: '80%', marginTop: '50px', }}>
                <Box className="ContSecundarioN">

                  <Box sx={BoxConFondo}>
                    <div className="SombraN">
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className="TituloN" variant="h1" style={{ fontSize: 24, textTransform: 'uppercase', }}>
                            {Competencia.titulo}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {
                            (
                              Competencia.asunto
                              && (Competencia.asunto !== 'null' || Competencia.asunto !== 'undefined')
                              && (Competencia.asunto.startsWith('http://') ||
                                Competencia.asunto.startsWith('https://') ||
                                Competencia.asunto.startsWith('www.')))
                              ?
                              <Button
                                component="a"
                                href={Competencia.asunto.startsWith('www.') ? 'https://' + Competencia.asunto : Competencia.asunto}
                                target="_blank"
                                className="SubtituloN"
                                sx={{ color: '#fff' }}
                                style={{ textTransform: 'uppercase', wordBreak: "break-word" }}
                              >
                                {
                                  Competencia.asunto
                                }
                              </Button>
                              :
                              <Typography sx={{ px: 5, WebkitBoxOrient: 'vertical', lineHeight: 2, }} gutterBottom variant="body2"
                                style={{ textTransform: 'uppercase', }}
                              >
                                {
                                  Competencia.asunto &&
                                    (Competencia.asunto.toLowerCase() === 'null' || Competencia.asunto.toLowerCase() === 'undefined')
                                    ? ''
                                    : Competencia.asunto}
                              </Typography>
                          }
                        </Grid>

                        <Grid item xs={12}>
                          <Typography className="SubtituloN" >
                            {'Modalidad:'} {modalidad}
                          </Typography>
                        </Grid>

                      </Grid>
                      {
                        Origen === 'auth' &&
                        <Box sx={{ display: 'flex', width: '100%', gap: 5, pr: 5, justifyContent: 'flex-end' }}>
                          <IconButton
                            aria-label="edit "
                            disabled={isDelete}
                            onClick={() => {
                              setIsEditable(!isEditable);
                            }}
                            color="primary" >
                            <EditIcon sx={{ fontSize: '1.5em' }} />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setIsDelete(!isDelete)
                            }}
                            color="primary" >
                            <DeleteIcon sx={{ fontSize: '1.5em' }} />
                          </IconButton>
                          <ModalConfirmacion />
                        </Box>
                      }
                    </div>
                  </Box>

                  {
                    isDelete &&
                    <>
                      <Grid item sx={{ pt: 3, display: 'flex', justifyContent: 'center' }}>
                        <Typography><strong style={{ fontSize: 20, fontWeight: 'bold', display: 'block' }}>¿Está seguro de borrar esta Competencia?</strong></Typography>
                      </Grid>
                      <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography><strong style={{ fontSize: 20, fontWeight: 'bold', display: 'block' }}>Se borrarán todos los resultados asociados a la competencia</strong></Typography>
                      </Grid>
                    </>
                  }

                  <Box className="ContenidoN">
                    <Grid container className="GridContenedorN">
                      <Grid item>
                        <TextField
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            inputComponent: FormattedInput,
                            value: Competencia.desarrollo,
                          }}

                          style={{ height: '100%', }}
                          label="Desarrollo"
                          name="desarrollo"
                          value={Competencia.desarrollo}
                          fullWidth
                          required
                          multiline
                          rows={9}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider className="DividerN" />
                  <Grid item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {
                        dataImagenes?.listaImagenes?.map((item, index) =>
                          (/^[A-Z]:\\/.test(item))
                            ?
                            <>

                            </>
                            :
                            <div key={index} className="imageContainer">
                              <img
                                style={{ width: '100px', height: '100px', margin: 5 }}
                                src={`${API_BASE_URL}/images/${item}`}
                                alt={`Imagenes ${index}`} />
                            </div>
                        )
                      }
                    </div>
                  </Grid>
                  <Divider className="DividerN" />

                  <Grid container sx={{ justifyContent: 'space-evenly', my: 3 }}>
                    <Grid item >
                      <Typography className="Subtitulo" style={{ fontSize: 14 }}>
                        Inicio:  {Competencia.fechaInicio}
                      </Typography>

                    </Grid>
                    <Grid item >
                      <Typography className="Subtitulo" style={{ fontSize: 14 }}>
                        Ubicación: {Competencia.ubicacion}
                      </Typography>

                    </Grid>
                    <Grid item >
                      <Typography className="Subtitulo" style={{ fontSize: 14 }}>
                        Finalización: {Competencia.fechaFin}
                      </Typography>

                    </Grid>
                  </Grid>

                  <Divider className="DividerN" />
                  <Grid className='gridBotonesMC'>
                    {!isEditable
                      ?
                      isDelete
                        ?
                        <>
                          <Grid container sx={{ justifyContent: 'end', py: 2 }}>
                            <LoadingButton loading={isFetching} onClick={handleDelete} className='BotonBody'>Eliminar</LoadingButton>
                            <Button onClick={handleCancelar} className='BotonBody'>Cancelar</Button>
                          </Grid>
                          <ModalConfirmacion />
                        </>
                        :
                        <>
                        </>
                      :
                      <Grid container sx={{ justifyContent: 'end' }}>
                        <LoadingButton loading={isUpdating} onClick={handleEdit}
                          className='BotonBody'>Guardar</LoadingButton>
                        <Button onClick={handleCancelar} className='BotonBody' >Cancelar</Button>
                      </Grid>

                    }
                  </Grid>

                </Box>
              </Box>
            </>
        }
      </Modal >
    </React.Fragment >
  );
}

export default ModalCompetencia